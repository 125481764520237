import OrderStatusApiExternalHttpApiClient from '@acme/external-order-status-api-api-client';

import { config } from '../config';

const request = new OrderStatusApiExternalHttpApiClient(config.apiUrl);

export async function getCryptoshopDeltaOrderState(csOrderId: string) {
  return request.orderStatusApiGetCryptoshopDeltaOrderState({
    cs_order_id: csOrderId,
  });
}

export async function getBitfinexOrdersOrderState(boOrderId: string) {
  return request.orderStatusApiGetBitfinexOrdersOrderState({
    bo_order_id: boOrderId,
  });
}
