import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { Route, Switch } from 'wouter';
import './i18n/config';

import theme from './utils/theme';
import { Layout } from './components/Layout';
import { CsOrder } from './components/CsOrder';
import { BoOrder } from './components/BoOrder';

export const App = () => (
  <ThemeProvider theme={theme}>
    <Layout>
      <Switch>
        <Route path="/csdo/:orderId" component={CsOrder} />
        <Route path="/bo/:orderId" component={BoOrder} />
        <Route component={() => null} />
      </Switch>
    </Layout>
  </ThemeProvider>
);
