import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { CsOrderState } from '@acme/external-order-status-api-api-client/dist/types';
import { RouteComponentProps } from 'wouter';
import { useInterval } from 'react-use';
import { Skeleton } from '@material-ui/lab';

import { getCryptoshopDeltaOrderState } from '../../api';

import { RenderOrderDetails } from './RenderOrderDetails';
import { RenderOrderTimeline } from './RenderOrderTimeline';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 780,
    width: '100%',
    position: 'relative',
    marginTop: theme.spacing(3),
  },
  card: {
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  orderContentCard: {
    minHeight: 240,
  },
}));

const pollingStatuses = new Set([
  'new',
  'paid',
  'payout_initiated',
  'payout_processing',
]);

export const CsOrder = ({
  params,
}: RouteComponentProps<{ orderId: string }>) => {
  const classes = useStyles();

  const [orderState, setOrderState] = useState<CsOrderState>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const fetchOrderState = useCallback(async () => {
    const response = await getCryptoshopDeltaOrderState(params.orderId);
    if (response.http_status_code === 200) {
      setErrorMessage(undefined);
      setOrderState(response.cs_order_state);
    } else {
      setErrorMessage(response.error_message || response.error_code);
    }
  }, [params.orderId]);

  useEffect(() => {
    void fetchOrderState();
  }, [fetchOrderState]);

  const intervalTimeout = useMemo(() => {
    if (orderState) {
      if (pollingStatuses.has(orderState.status)) {
        return 5 * 1000;
      }
    }
    return null;
  }, [orderState]);

  useInterval(fetchOrderState, intervalTimeout);

  return (
    <div className={classes.wrapper}>
      <Card className={classes.card}>
        <CardContent className={classes.titleWrapper}>
          <Typography variant="h1" style={{ width: '100%' }}>
            {orderState ? orderState.caption : <Skeleton />}
          </Typography>
        </CardContent>
        <Divider />
        <CardContent>
          {errorMessage ? (
            <Typography variant="body1" color="error">
              {errorMessage}
            </Typography>
          ) : (
            <RenderOrderDetails orderState={orderState} />
          )}
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <RenderOrderTimeline orderState={orderState} />
        </CardContent>
      </Card>
    </div>
  );
};
