import React, { memo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { CsOrderState } from '@acme/external-order-status-api-api-client/dist/types';

const useStyles = makeStyles({
  details: {
    wordBreak: 'break-word',
  },
});

export const RenderOrderDetails = memo(
  ({ orderState }: { orderState?: CsOrderState }) => {
    const classes = useStyles();

    if (orderState) {
      if (orderState.error_details) {
        return (
          <Typography variant="body1" color="error">
            {orderState.error_details}
          </Typography>
        );
      }
      return (
        <div>
          <Typography variant="h1">
            {orderState.currency_amount} {orderState.currency}
          </Typography>
          {orderState.cart_html && (
            <Typography
              variant="body1"
              className={classes.details}
              dangerouslySetInnerHTML={{ __html: orderState.cart_html }}
            />
          )}
        </div>
      );
    }

    return (
      <div>
        <Typography variant="h1">
          <Skeleton />
        </Typography>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
      </div>
    );
  },
);
