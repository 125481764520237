import React, {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CssBaseline,
  makeStyles,
  Snackbar,
  SnackbarContent,
  IconButton,
  Typography,
  Container,
  Grid,
  NativeSelect,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import cx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createMuiTheme';

import LogoSvg from '../../assets/logo.svg';
import BitfinexLogoSvg from '../../assets/bitfinex-logo.svg';
import LangIconSvg from '../../assets/lang-icon.svg';
import TwIcon from '../../assets/social-media-icons/twitter-icon.svg';
import FbIcon from '../../assets/social-media-icons/fb-icon.svg';
import TgIcon from '../../assets/social-media-icons/tg-icon.svg';
import BttIcon from '../../assets/social-media-icons/btt-icon.svg';
import MediumIcon from '../../assets/social-media-icons/medium-icon.svg';
import YoutubeIcon from '../../assets/social-media-icons/youtube-icon.svg';
import InstagramIcon from '../../assets/social-media-icons/instagram-icon.svg';
import RedditIcon from '../../assets/social-media-icons/reddit-icon.svg';
import WeChatIcon from '../../assets/social-media-icons/wechat-icon.svg';
import DssIcon from '../../assets/payments-icons/dss-icon.svg';
import MasterCardIcon from '../../assets/payments-icons/mastercard-icon.svg';
import VisaIcon from '../../assets/payments-icons/visa-icon.svg';
import UnionpayIcon from '../../assets/payments-icons/unionpay-icon.svg';
import SepaIcon from '../../assets/payments-icons/sepa-icon.svg';
import SwiftIcon from '../../assets/payments-icons/swift-icon.svg';
import FincenIcon from '../../assets/fincen-icon.svg';
import MosttruthwothyIcon from '../../assets/mosttruthwothy-icon.svg';
import { useSnackbar, SnackbarType } from '../../hooks/useSnackbar';

const useStyles = makeStyles<DefaultTheme, { isBitfinex: boolean }>(
  (theme) => ({
    root: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: (props) => (props.isBitfinex ? '#fff' : '#F3F6F9'),
    },
    header: {
      width: '100%',
      position: 'relative',
      paddingTop: 20,
    },
    headerLine: {
      backgroundColor: theme.palette.primary.main,
      height: 10,
      [theme.breakpoints.down('sm')]: {
        height: 40,
      },
    },
    logo: {
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        width: 117,
      },
    },
    footer: {
      paddingTop: 50,
      paddingBottom: 60,
      width: '100%',
      position: 'relative',
      backgroundColor: '#262241',
      [theme.breakpoints.down('sm')]: {},
    },
    footerLicense: {
      maxWidth: 680,
      marginTop: 32,
    },
    license: {
      fontSize: 13,
    },
    licenseTitle: {
      color: '#fff',
    },
    container: {
      flex: 1,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      paddingTop: 110,
      paddingBottom: 130,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 25,
      },
    },
    snackbarError: {
      backgroundColor: theme.palette.error.main,
    },
    snackbarSuccess: {
      backgroundColor: theme.palette.success.main,
    },
    langSelectWrapper: {
      display: 'flex',
      width: 180,
      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
      },
    },
    arrowDropdownIcon: {
      position: 'absolute',
      right: 6,
      top: 3,
      pointerEvents: 'none',
    },
    langSelect: {
      width: '100%',
      color: '#fff',
      fontSize: 16,
      '&:before': {
        borderColor: '#9293B0',
      },
    },
    langSelectRoot: {
      paddingLeft: 30,
    },
    langIcon: {
      marginRight: -20,
    },
    langSelectIcon: {
      color: '#9293B0',
    },
    logoGrid: {
      display: 'flex',
    },
    bitfinexLogo: {
      display: (props) => (props.isBitfinex ? 'inline-block' : 'none'),
      marginLeft: 24,
      paddingLeft: 24,
      borderLeft: '1px dashed #121A25',
      [theme.breakpoints.down('sm')]: {
        width: 147,
      },
    },
    footerIcon: {
      '&:not(:last-child)': {
        marginRight: 20,
      },
    },
    paymentsIcons: {
      marginTop: 40,
    },
    aboutUsLinks: {
      color: '#fff',
      marginTop: 40,
      display: 'flex',
      flexDirection: 'column',
    },
    link: {
      textDecoration: 'none',
    },
    footerActions: {
      marginTop: 40,
    },
    footerAction: {
      width: 250,
      color: '#B0B0FF',
      borderColor: '#B0B0FF',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: 10,
      },
    },
    installOwnr: {
      marginRight: 20,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
    },
    rewardsIcons: {
      marginTop: 40,
    },
    rewardsIcon: {
      marginRight: 40,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        marginBottom: 10,
      },
    },
  }),
);

const socialMediaIcons = [
  {
    name: 'Twitter',
    href: 'https://twitter.com/ownrwallet',
    icon: TwIcon,
  },
  {
    name: 'Facebook',
    icon: FbIcon,
    href: 'https://www.facebook.com/ownrwallet',
  },
  {
    name: 'Telegram',
    icon: TgIcon,
    href: 'https://t.me/ownrwallet',
  },
  {
    name: 'Bitcointalk',
    icon: BttIcon,
    href: 'https://bitcointalk.org/index.php?topic=5145162.0',
  },
  {
    name: 'Medium',
    icon: MediumIcon,
    href: 'https://medium.com/ownrwallet',
  },
  {
    name: 'Youtube',
    icon: YoutubeIcon,
    href: 'https://www.youtube.com/ownrwallet',
  },
  {
    name: 'Instagram',
    icon: InstagramIcon,
    href: 'https://www.instagram.com/ownr_wallet',
  },
  {
    name: 'Reddit',
    icon: RedditIcon,
    href: 'https://www.reddit.com/r/ownrwallet',
  },
  {
    name: 'WeChat',
    icon: WeChatIcon,
    href: '',
  },
];

const paymentsIcons = [
  {
    name: 'DSS',
    icon: DssIcon,
  },
  {
    name: 'Mastercard',
    icon: MasterCardIcon,
  },
  {
    name: 'Visa',
    icon: VisaIcon,
  },
  {
    name: 'Union Pay',
    icon: UnionpayIcon,
  },
  {
    name: 'Sepa',
    icon: SepaIcon,
  },
  {
    name: 'Swift',
    icon: SwiftIcon,
  },
];

export const Layout = memo(({ children }: PropsWithChildren<unknown>) => {
  const [location] = useLocation();
  const isBitfinex = useMemo(() => location.includes('/bo/'), [location]);

  const classes = useStyles({ isBitfinex });
  const { i18n, t } = useTranslation();

  const [lang, setLang] = useState(i18n.language);

  const onChangeLanguage = useCallback(
    (evt: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      setLang(evt.target.value as string);
    },
    [],
  );

  useEffect(() => {
    void i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const { snackbarValue, setSnackbarValue, snackbarType } = useSnackbar();

  const onSnackbarClose = useCallback(() => {
    setSnackbarValue('');
  }, [setSnackbarValue]);

  const footerLinks = useMemo(
    () => [
      {
        name: t('common.about'),
        link: 'https://ownrwallet.com/about',
      },
      {
        name: t('common.cards'),
        link: 'https://card.ownrwallet.com',
      },
      {
        name: t('common.download_wallet'),
        link: 'https://ownrwallet.com/#download',
      },
      {
        name: t('common.buy_crypto'),
        link: 'https://buy.ownrwallet.com',
      },
      {
        name: t('common.advantages'),
        link: 'https://ownrwallet.com/#advantages',
      },
      {
        name: t('common.privacy'),
        link: 'https://ownrwallet.com/privacy',
      },
      {
        name: t('common.terms'),
        link: 'https://ownrwallet.com/terms',
      },
      {
        name: t('common.support'),
        link: 'https://ownrwallet.freshdesk.com/support/tickets/new',
      },
      {
        name: t('common.knowledge_base'),
        link: 'https://ownrwallet.com/support/ru/',
      },
      {
        name: t('common.blog'),
        link: 'https://ownrwallet.com/blog/',
      },
      {
        name: t('common.promo'),
        link: 'https://ownrwallet.com/promo',
      },
      {
        name: t('common.public_api'),
        link: 'https://cryptoshop-order-api.ownrwallet.com/',
      },
      {
        name: 'API for crypto-to-crypto exchange',
        link:
          'https://ownrwallet.com/support/guide-for-api-cryptocurrency-exchange/',
      },
    ],
    [t],
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <header className={classes.header}>
        <Container>
          <Grid container>
            <Grid item xs={6} sm={6} className={classes.logoGrid}>
              <img
                className={classes.logo}
                src={LogoSvg}
                alt="Logo of OWNR wallet"
              />
              <img
                className={classes.bitfinexLogo}
                src={BitfinexLogoSvg}
                alt="Bitfinex logo"
              />
            </Grid>
            <Grid item xs={4} sm={5} />
            <Grid item xs={2} sm={1} />
          </Grid>
        </Container>
      </header>
      <Container className={classes.container}>
        <main className={classes.content}>{children}</main>
      </Container>
      <footer className={classes.footer}>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={8}>
              {socialMediaIcons.map((icon) => (
                <a
                  className={classes.footerIcon}
                  key={icon.name}
                  href={icon.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icon.icon} alt={icon.name} />
                </a>
              ))}
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className={classes.langSelectWrapper}>
                <img src={LangIconSvg} className={classes.langIcon} alt="" />
                <NativeSelect
                  value={lang}
                  onChange={onChangeLanguage}
                  className={classes.langSelect}
                  classes={{
                    root: classes.langSelectRoot,
                    icon: classes.langSelectIcon,
                  }}
                >
                  <option value="en-EN">English (EN)</option>
                  <option value="ru-RU">Русский (RU)</option>
                </NativeSelect>
              </div>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column">
                <Grid item xs className={classes.paymentsIcons}>
                  {paymentsIcons.map((p) => (
                    <img
                      className={classes.footerIcon}
                      key={p.name}
                      src={p.icon}
                      alt={p.name}
                    />
                  ))}
                </Grid>
                <Grid item xs>
                  <div className={classes.footerLicense}>
                    <Typography
                      className={classes.license}
                      color="textSecondary"
                      gutterBottom
                    >
                      WNR WALLET OÜ (registration code - 14555701) is registered
                      at Harju maakond, Tallinn, Kesklinna linnaosa,
                      Väike-Ameerika tn 8-421, 10129, and holds the license
                      FVT000406 for providing services of exchanging a virtual
                      currency against a fiat currency and providing a virtual
                      currency wallet service.
                    </Typography>
                    <Typography
                      className={classes.license}
                      color="textSecondary"
                      gutterBottom
                    >
                      <span className={classes.licenseTitle}>
                        Registered companies with licenses:
                      </span>
                      <br />
                      OWNR WALLET OÜ - 14555701, License FVT000406, Estonia
                      <br />
                      OWNR WALLET LLC - 7826216, MSB registration number
                      31000168328178, USA
                      <br />
                      OWNR WALLET CRYPTO LTD - BC1274005, MSB registration
                      number M20029873, Canada
                      <br />
                      OWNR WALLET AU PTY. LTD. - 649 022 337, Registered
                      provider number DCE100749485-001, Australia
                      <br />
                      OWNR WALLET EUROPE S.R.O - 106 65 587, Czech Republic
                    </Typography>
                    <Typography
                      className={classes.license}
                      color="textSecondary"
                    >
                      <span className={classes.licenseTitle}>
                        Registered companies without licenses:
                      </span>
                      <br />
                      OWNR WALLET LIMITED - 2955843, Hong Kong
                      <br />
                      OWNR WALLET UK LTD - 12924828, United Kingdom
                    </Typography>
                  </div>
                </Grid>
                <Grid className={classes.footerActions} item xs>
                  <a
                    className={cx(classes.link, classes.installOwnr)}
                    href="https://ownrwallet.com/#download"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant="outlined"
                      size="large"
                      type="button"
                      color="primary"
                      className={classes.footerAction}
                    >
                      {t('common.download_ownr')}
                    </Button>
                  </a>
                  <a
                    className={classes.link}
                    href="https://buy.ownrwallet.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant="outlined"
                      size="large"
                      type="button"
                      color="primary"
                      className={classes.footerAction}
                    >
                      {t('common.buy_crypto')}
                    </Button>
                  </a>
                </Grid>
                <Grid className={classes.rewardsIcons} item xs>
                  <img
                    className={classes.rewardsIcon}
                    src={FincenIcon}
                    alt=""
                  />
                  <img src={MosttruthwothyIcon} alt="" />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={4}
                className={classes.aboutUsLinks}
              >
                {footerLinks.map((l) => (
                  <Typography
                    key={l.link}
                    className={classes.link}
                    href={l.link}
                    component="a"
                    variant="body1"
                    color="inherit"
                    target="_blank"
                    rel="noopener noreferrer"
                    gutterBottom
                  >
                    {l.name}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </footer>
      <Snackbar
        open={Boolean(snackbarValue)}
        onClose={onSnackbarClose}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SnackbarContent
          className={cx({
            [classes.snackbarError]: snackbarType === SnackbarType.Error,
            [classes.snackbarSuccess]: snackbarType === SnackbarType.Success,
          })}
          message={snackbarValue}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={onSnackbarClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </div>
  );
});
