import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'wouter';
import {
  BoOrderState,
  BoOrderStatus,
} from '@acme/external-order-status-api-api-client/dist/types';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import cx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'react-use';

import ArrowRight from '../../assets/arrow-right.svg';
import Union from '../../assets/union.svg';
import Dot from '../../assets/dot.svg';
import Check from '../../assets/check-icon.svg';
import Pending from '../../assets/pending-icon.svg';
import DotIcon from '../../assets/dot-icon.svg';
import ErrorIcon from '../../assets/error-icon.svg';
import ExpiredIcon from '../../assets/expired-icon.svg';
import GearsIcon from '../../assets/gears-icon.svg';
import RUB from '../../assets/currency-icons/RUB.svg';
import USD from '../../assets/currency-icons/USD.svg';
import EUR from '../../assets/currency-icons/EUR.svg';
import GBP from '../../assets/currency-icons/GBP.svg';
import CNY from '../../assets/currency-icons/CNY.svg';
import BTC from '../../assets/currency-icons/BTC.svg';
import BCH from '../../assets/currency-icons/BCH.svg';
import LTC from '../../assets/currency-icons/LTC.svg';
import DASH from '../../assets/currency-icons/DASH.svg';
import ETC from '../../assets/currency-icons/ETC.svg';
import ETH from '../../assets/currency-icons/ETH.svg';
import USDT from '../../assets/currency-icons/USDT.svg';
import EOS from '../../assets/currency-icons/EOS.svg';
import LEO from '../../assets/currency-icons/LEO.svg';
import XTZ from '../../assets/currency-icons/XTZ.svg';
import XAUT from '../../assets/currency-icons/XAUT.svg';
import TRX from '../../assets/currency-icons/TRX.svg';
import { getBitfinexOrdersOrderState } from '../../api';

const useStyles = makeStyles((theme) => ({
  title: {
    alignSelf: 'flex-start',
    marginBottom: 45,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  card: {
    width: '100%',
    borderRadius: 10,
    color: '#fff',
    background:
      'radial-gradient(98.25% 123.62% at -1.09% 0%, #625B89 0%, #2B2D3A 100%)',
  },
  divider: {
    marginTop: 25,
    marginBottom: 30,
    backgroundColor: '#969EAA',
    opacity: 0.5,
  },
  exchange: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  arrowRight: {
    margin: '0 42px',
    [theme.breakpoints.down('sm')]: {
      transform: 'rotateZ(90deg)',
      margin: '10px 5px',
    },
  },
  titleLight: {
    fontWeight: 300,
  },
  titleBold: {
    fontWeight: 700,
  },
  currencyTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  statusTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  statusTitleIcon: {
    marginRight: 30,
  },
  dividerDotted: {
    margin: '20px 0',
    position: 'relative',
    background: `url(${Dot}) center`,
    height: 4,
    '&:before': {
      content: '""',
      background: `url(${Union})`,
      position: 'absolute',
      left: 0,
      top: -10,
      width: 12,
      height: 24,
    },
    '&:after': {
      content: '""',
      background: `url(${Union})`,
      transform: 'scaleX(-1)',
      position: 'absolute',
      right: 0,
      top: -10,
      width: 12,
      height: 24,
    },
  },
  statusWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    color: '#969EAA',
    '&:not(:last-child)': {
      marginBottom: 20,
    },
  },
  statusActive: {
    color: '#fff',
  },
  statusIcon: {
    marginRight: 10,
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  link: {
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  currencyIcon: {
    width: 40,
    height: 40,
    marginRight: 30,
    [theme.breakpoints.down('sm')]: {
      marginRight: 15,
    },
  },
  orderDate: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    },
  },
  payoutAddress: {
    wordBreak: 'break-all',
  },
}));

const orderStatuses: Array<BoOrderStatus> = [
  'new',
  'paid',
  'processing',
  'payout',
  'finished',
];

const statusIconMap = {
  past: Check,
  current: Pending,
  next: DotIcon,
};

const currencyMap = {
  RUB,
  USD,
  EUR,
  GBP,
  CNY,
  BTC,
  BCH,
  LTC,
  DASH,
  ETH,
  ETC,
  USDT,
  EOS,
  LEO,
  XTZ,
  XAUT,
  TRX,
};

const pollingStatuses = new Set(['new', 'paid', 'payout', 'processing']);

export const BoOrder = ({
  params,
}: RouteComponentProps<{ orderId: string }>) => {
  const classes = useStyles();
  const [orderState, setOrderState] = useState<BoOrderState>();

  const { t } = useTranslation();

  const orderStatusMap = useMemo<{ [K in BoOrderStatus]: string }>(
    () => ({
      new: t('orderStatus.orderCreated'),
      paid: t('orderStatus.paymentAccepted'),
      expired: t('payform.expired'),
      processing: t('orderStatus.orderProcessed'),
      payout: t('orderStatus.payoutInitiated'),
      finished: t('orderStatus.cryptoOnTheWay'),
      error: t('orderStatus.error'),
    }),
    [t],
  );

  const fetchOrder = useCallback(async () => {
    const response = await getBitfinexOrdersOrderState(params.orderId);
    if (response.http_status_code === 200) {
      setOrderState(response.bo_order_state);
    }
  }, [params.orderId]);

  useEffect(() => {
    void fetchOrder();
  }, [fetchOrder]);

  const intervalTimeout = useMemo(() => {
    if (orderState) {
      if (pollingStatuses.has(orderState.status)) {
        return 5 * 1000;
      }
    }
    return null;
  }, [orderState]);

  useInterval(fetchOrder, intervalTimeout);

  const statuses = useMemo(
    () =>
      orderStatuses.map((s, i) => {
        const index = orderState ? orderStatuses.indexOf(orderState.status) : 0;
        let iconStatus: keyof typeof statusIconMap = 'current';
        if (index > i) {
          iconStatus = 'past';
        }
        if (index < i) {
          iconStatus = 'next';
        }
        return (
          <Typography
            key={s}
            variant="body1"
            className={cx(classes.status, {
              [classes.statusActive]: index === i,
            })}
          >
            <img
              className={classes.statusIcon}
              src={statusIconMap[iconStatus]}
              alt=""
            />
            {orderStatusMap[s]}
          </Typography>
        );
      }),
    [
      orderState,
      classes.status,
      classes.statusActive,
      classes.statusIcon,
      orderStatusMap,
    ],
  );

  const redirectUrl = useMemo(
    () =>
      orderState &&
      (orderStatuses.includes(orderState.status)
        ? orderState.url_on_success
        : orderState.url_on_failure),
    [orderState],
  );

  if (!orderState) {
    return null;
  }

  return (
    <>
      <Typography className={classes.title} variant="h1">
        {t('orderStatus.orderDetails')}:
      </Typography>
      <Card elevation={0} className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid className={classes.orderDate} item sm={4} xs={12}>
              <Typography variant="caption">
                {t('orderStatus.orderDate')}:
              </Typography>
              <Typography variant="h4">
                {new Date(orderState.created_at).toLocaleString()}
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Typography variant="caption">{t('payform.address')}:</Typography>
              <Typography className={classes.payoutAddress} variant="h4">
                {orderState.payout_address}
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <div className={classes.exchange}>
            <Typography
              variant="h2"
              className={cx(classes.currencyTitle, classes.titleLight)}
            >
              <img
                className={classes.currencyIcon}
                src={
                  currencyMap[
                    orderState.fiat_currency as keyof typeof currencyMap
                  ]
                }
                alt=""
              />
              {orderState.fiat_currency_amount} {orderState.fiat_currency}
            </Typography>
            <img className={classes.arrowRight} src={ArrowRight} alt="" />
            <Typography
              variant="h2"
              className={cx(classes.currencyTitle, classes.titleBold)}
            >
              <img
                className={classes.currencyIcon}
                src={
                  currencyMap[
                    orderState.cryptocurrency as keyof typeof currencyMap
                  ]
                }
                alt=""
              />
              {orderState.cryptocurrency_amount} {orderState.cryptocurrency}
            </Typography>
          </div>
        </CardContent>
        <div className={classes.dividerDotted} />
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={4} className={classes.statusWrapper}>
              <Typography className={classes.statusTitle} variant="h2">
                <img
                  className={classes.statusTitleIcon}
                  src={GearsIcon}
                  alt=""
                />
                {t('orderStatus.status')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.statusWrapper}>
              {orderState.status === 'expired' && (
                <Typography
                  variant="body1"
                  className={cx(classes.status, classes.statusActive)}
                >
                  <img
                    className={classes.statusIcon}
                    src={ExpiredIcon}
                    alt=""
                  />
                  {orderStatusMap.expired}
                </Typography>
              )}
              {orderState.status === 'error' && (
                <Typography
                  variant="body1"
                  className={cx(classes.status, classes.statusActive)}
                >
                  <img className={classes.statusIcon} src={ErrorIcon} alt="" />
                  {orderStatusMap.error}
                </Typography>
              )}
              {orderStatuses.includes(orderState.status) && statuses}
            </Grid>
            <Grid item sm={4} xs={12} className={classes.actions}>
              {redirectUrl && (
                <a className={classes.link} href={redirectUrl}>
                  <Button
                    size="large"
                    type="button"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    {t('orderStatus.backToBitfinex')}
                  </Button>
                </a>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
