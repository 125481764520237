import React, { memo, useCallback, useMemo } from 'react';
import { CsOrderState } from '@acme/external-order-status-api-api-client/dist/types';
import {
  Button,
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

import OrderRocketIcon from '../../assets/order-rocket-icon.svg';
import DoneStatusIcon from '../../assets/done-status-icon.svg';
import CurrentStatusIcon from '../../assets/current-status-icon.svg';
import InactiveStatusIcon from '../../assets/inactive-status-icon.svg';
import OrderExpiredSvg from '../../assets/order-expired-status.svg';
import ErrorSvg from '../../assets/order-error.svg';

interface IProps {
  orderState?: CsOrderState;
}

const useStyles = makeStyles((theme) => ({
  orderStatusIcon: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(5),
  },
  orderStatesTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 118,
    marginBottom: theme.spacing(2),
  },
  errorDescription: {
    width: 'calc(100% - 80px)',
    marginBottom: theme.spacing(2),
  },
  stepper: {
    paddingLeft: 0,
  },
  connector: {
    paddingTop: theme.spacing(1),
    marginLeft: 10,
  },
  link: {
    textDecoration: 'none',
  },
}));

const statuses = [
  'awaiting_payment',
  'fiat_paid',
  'payout',
  'completed',
] as const;

const statusIconsMap = {
  inactive: InactiveStatusIcon,
  current: CurrentStatusIcon,
  completed: DoneStatusIcon,
};

export const RenderOrderTimeline = memo(({ orderState }: IProps) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const orderStateStatus = useMemo<typeof statuses[number]>(() => {
    switch (orderState?.status) {
      case 'fiat_paid':
        return 'fiat_paid';
      case 'payout':
        return 'payout';
      case 'completed':
      case 'external_address_specified':
      case 'waiting_for_kyc':
        return 'completed';
      default:
        return 'awaiting_payment';
    }
  }, [orderState?.status]);

  const activeStep = useMemo(() => statuses.indexOf(orderStateStatus), [
    orderStateStatus,
  ]);

  const statusMap = useMemo<Record<typeof statuses[number], string>>(
    () => ({
      awaiting_payment: t('orderStatus.orderCreated'),
      fiat_paid: t('orderStatus.paymentAccepted'),
      payout: t('orderStatus.payoutInitiated'),
      completed: t('orderStatus.cryptoOnTheWay'),
    }),
    [t],
  );

  const getStepIconComponent = useCallback(
    (index: number) => {
      let orderStatus: 'completed' | 'current' | 'inactive' = 'inactive';

      if (index === statuses.indexOf(orderStateStatus)) {
        orderStatus = 'current';
      }

      if (
        index < statuses.indexOf(orderStateStatus) ||
        statuses.indexOf(orderStateStatus) === statuses.length - 1
      ) {
        orderStatus = 'completed';
      }

      return () => <img src={statusIconsMap[orderStatus]} alt="" />;
    },
    [orderStateStatus],
  );

  const timelineItems = useMemo(
    () =>
      statuses.map((status, index) => (
        <Step key={status}>
          <StepLabel StepIconComponent={getStepIconComponent(index)}>
            {statusMap[status]}
          </StepLabel>
        </Step>
      )),
    [getStepIconComponent, statusMap],
  );

  if (!orderState) {
    return (
      <>
        <Skeleton height={42} />
        <Skeleton height={140} />
      </>
    );
  }

  if (orderState.status === 'error') {
    return (
      <>
        <Typography variant="h2" color="error" gutterBottom>
          {t('payform.unknown_error.title')}
        </Typography>
        <img
          className={classes.orderStatusIcon}
          src={ErrorSvg}
          alt="Unknown error icon"
        />
        <Typography className={classes.errorDescription} variant="body1">
          {t('payform.unknown_error.description')}
        </Typography>

        {orderState.url_on_failure && (
          <a
            className={classes.link}
            href={orderState.url_on_failure}
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              size="large"
              type="button"
              color="primary"
            >
              {t('next')}
            </Button>
          </a>
        )}
      </>
    );
  }

  if (orderState.status === 'expired') {
    return (
      <>
        <Typography variant="h2" color="error" gutterBottom>
          {t('payform.order_expired.title')}
        </Typography>
        <img
          src={OrderExpiredSvg}
          alt="Order has been expired icon"
          className={classes.orderStatusIcon}
        />
        <Typography className={classes.errorDescription} variant="body1">
          {t('payform.order_expired.description')}
        </Typography>

        {orderState.url_on_failure && (
          <a
            className={classes.link}
            href={orderState.url_on_failure}
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              size="large"
              type="button"
              color="primary"
            >
              {t('next')}
            </Button>
          </a>
        )}
      </>
    );
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        {t('orderStatus.status')}
      </Typography>
      <img
        className={classes.orderStatusIcon}
        src={OrderRocketIcon}
        alt="Status icon"
      />

      <Stepper
        className={classes.stepper}
        activeStep={activeStep}
        orientation="vertical"
        connector={<StepConnector className={classes.connector} />}
      >
        {timelineItems}
      </Stepper>

      {orderState.url_on_success && (
        <a
          className={classes.link}
          href={orderState.url_on_success}
          rel="noopener noreferrer"
        >
          <Button
            variant="contained"
            size="large"
            type="button"
            color="primary"
          >
            {t('next')}
          </Button>
        </a>
      )}
    </>
  );
});
