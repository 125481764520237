import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from '@acme/ownr-i18n/dist/locales/en.json';
import ru from '@acme/ownr-i18n/dist/locales/ru.json';

const resources: Resource = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  resources,
});
